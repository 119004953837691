<template>
    <!-- <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    left
  > -->
    <!-- <template #button-content> -->
    <router-link to="/admin/contactUs">
        <b-badge
            v-if="unreadMessages == true"
            variant="danger"
            class="rounded-circle position-relative"
            style="top: -10px; right: 14px"
        >
            <span
                class="rounded-circle position-relative"
                style="top: 3px; right: 0px"
            >
                {{ "*" }}
            </span>
        </b-badge>

        <unicon
            wdith="18"
            :fill="`${isDark ? '#fff' : '#2EC4B6'}`"
            name="envelope-download"
        />
    </router-link>
    <!-- </template> -->
    <!-- Notifications -->
    <!-- <vue-perfect-scrollbar
      v-if="unreadMessages == true"
      v-once
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-link
        v-for="notification in notificationsDash"
        :key="notification.id"
      >
        <b-media @click="notification.posId != null ? goto(notification.posId) : ''">
          <p class="media-heading" style="display: flex; justify-content: space-between;">
            <span class="font-weight-bolder">
              {{ notification.message }}
            </span>
            {{notification.isRead}}
            <b-badge
               v-if="!notification.isRead && notificationCount"
               variant="danger" 
               class="rounded-circle position-relative" 
               >
            </b-badge>
          </p>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar> -->

    <!-- Cart Footer -->
    <!-- <li class="dropdown-menu-footer" v-if="notificationsDash.length > 0">
      <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      >تمييز كمقروء</b-button>
    </li> -->
    <!-- <div v-else class="p-1">
      لا توجد إشعارات جديدة
    </div> -->
    <!--     
  </b-nav-item-dropdown> -->
</template>

<script>
import { BBadge } from "bootstrap-vue";
import store from "@/store";
// import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from "vue-ripple-directive";
import useAppConfig from "@core/app-config/useAppConfig";
import { computed } from "@vue/composition-api";
import { mapActions } from "vuex";
import router from "@/router";
export default {
    mounted() {
        // Dispatch the action to get unread messages
        store
            .dispatch("getGetUnRead")
            .then((data) => {
                // Access the data from the store and use it
                this.unreadMessages = data;
            })
            .catch((error) => {
                console.error(
                    "Error getting unread messages in another page:",
                    error
                );
            });

        console.log("unreadMessages ", this.unreadMessages);
    },
    components: {
        // BNavItemDropdown,
        BBadge,
        // BMedia,
        // BLink,
        // VuePerfectScrollbar,
        // BButton,
    },
    directives: {
        Ripple,
    },
    setup() {
        const { skin } = useAppConfig();
        const isDark = computed(() => skin.value === "dark");
        return {
            isDark,
        };
    },
    data: () => ({
        notificationsDash: [],
        unreadMessages: false,
    }),
    computed: {},
    methods: {
        ...mapActions(["getGetUnRead"]),
        redirectToContactUs() {
            router.push("/admin/services/contactUs");
        },
    },
    created() {
        this.getGetUnRead();
    },
};
</script>
